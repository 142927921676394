<template>
  <div>
    &nbsp;
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>

</style>
